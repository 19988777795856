<template>
  <div id="shop">
    <header>
      <div class="headerTop">
        <div class="container">
          <div class="logo">
            <h1>
              <img src="@/assets/images/logo.png" alt="logo" />
            </h1>
          </div>
          <ul>
            <li><router-link to="/">首页</router-link></li>
            <li class="cur"><router-link to="wisdom">智量百业</router-link></li>
            <li><router-link to="shop">同城好店</router-link></li>
            <li><router-link to="assistant">抖微助手</router-link></li>
            <li><router-link to="flyfishoutlets">飞鱼系统</router-link></li>
            <li><router-link to="commerce">跨境电商</router-link></li>
            <!-- <li>
              <router-link to="contact">联系我们</router-link>
            </li> -->
            <li><router-link to="about">关于我们</router-link></li>
          </ul>
        </div>
      </div>
      <img src="@/assets/images/wisdom-banner.jpg" alt="" />
    </header>

    <section style="text-align: center">
      <img src="@/assets/images/wisdom_03.jpg" alt="" />
      <img src="@/assets/images/wisdom_04.jpg" alt="" />
      <img src="@/assets/images/wisdom_05.jpg" alt="智量百业" />
      <img src="@/assets/images/wisdom_06.jpg" alt="" />
      <img src="@/assets/images/wisdom_07.jpg" alt="" />
      <img src="@/assets/images/wisdom_08.jpg" alt="" />
    </section>

    <!-- 
    <section class="container pd100 shop">
      <div class="title">
        <h4>
          <img src="@/assets/images/shop.jpg" alt="同城好店" />
          <span>同城好店</span>
        </h4>
        <p>同城3-5公里精准引流服务</p>
      </div>
      <div class="main">
        <div class="shop-bg">
          <img src="@/assets/images/shop-bg-1.png" alt="" />
        </div>
        <div class="item_circle item_circle1">
          <img src="@/assets/images/shop-1.jpg" alt="工具服务" />
          <p>抖音门店+同城好店</p>
          <p>工具服务</p>
        </div>
        <div class="item_circle item_circle2">
          <img src="@/assets/images/shop-2.jpg" alt="全城引流" />
          <p>精准用户三天裂变</p>
          <p>全城引流</p>
        </div>
        <div class="item_circle item_circle3">
          <img src="@/assets/images/shop-3.jpg" alt="拍摄剪辑" />
          <p>AI高质量短视频</p>
          <p>拍摄剪辑</p>
        </div>
        <div class="item_circle item_circle4">
          <img src="@/assets/images/shop-4.jpg" alt="设计支持" />
          <p>个性化活动海报</p>
          <p>设计支持</p>
        </div>
        <div class="item_circle item_circle5">
          <img src="@/assets/images/shop-5.jpg" alt="分析运营" />
          <p>抖音裂变数据</p>
          <p>分析运营</p>
        </div>
      </div>
    </section>
    <section class="container pd60 shop_1">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>你的门店是否还经历着？</span>
      </div>
      <div class="main">
        <img src="@/assets/images/shop-content.jpg" alt="" />
        <div class="item_circle item_circle1">
          <p>营销成本高，超低价让利引流，</p>
          <p>升单差，复购低？</p>
        </div>
        <div class="item_circle item_circle2">
          <p>不懂拍短视频、不懂流量运营，</p>
          <p>粉丝少，粘性差？</p>
        </div>
        <div class="item_circle item_circle3">
          <p>线上媒体和平台广告投入贵，</p>
          <p>引流效果不精准？</p>
        </div>
        <div class="item_circle item_circle4">
          <p>线上促销活动效果差，</p>
          <p>粉丝转化率低，到店顾客少？</p>
        </div>
        <div class="item_circle item_circle5">
          <p>门店各种优惠活动传播效果美，</p>
          <p>只影响小范围用户群？</p>
        </div>
        <div class="item_circle item_circle6">
          <p>老顾客不断流失，新客源少且不稳定，</p>
          <p>生意越来越难做？</p>
        </div>
      </div>
    </section>
    <section class="container pd100 shop_2">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>整体解决方案详情</span>
      </div>
      <div class="main">
        <div class="item">
          <img src="@/assets/images/shop_2-1.jpg" alt="" />
          <ul>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>根据商家情况开通抖音门店和同城好店</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>提供线上线下优惠活动服务支持</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>与线下实体店结合，同步高效引流裂变</span>
            </li>
          </ul>
        </div>
        <div class="item">
          <ul>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>
                根据店主用户画像及规则，
                <i>进行AI高质量短视频拍摄&剪辑</i>
              </span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>
                独创视频裂变引流系统，
                <i>1变300-1000、300-1000二次裂变引流</i>
              </span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>提供个性化抖音推广海报设计</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>1对1的专业抖音运营指导</span>
            </li>
            <li>
              <img src="@/assets/images/shop-icon.jpg" alt="" />
              <span>提供运营推广数据分析指导</span>
            </li>
          </ul>
          <img src="@/assets/images/shop_2-2.jpg" alt="" />
        </div>
      </div>
    </section>
    <section class="pd60 shop_3">
      <div class="container">
        <img src="@/assets/images/shop_3-content.jpg" alt="" />
        <div class="item item_1">
          <h4>引流到店</h4>
          <p>到店顾客扫码领取优惠</p>
        </div>
        <div class="item item_2">
          <h4>顾客领券</h4>
          <p>顾客抖音授权后自动领券</p>
          <p>并查看推广视频</p>
        </div>
        <div class="item item_3">
          <h4>顾客发布视频</h4>
          <p>顾客无需拍摄</p>
          <p>一键直接发布高质量视频</p>
        </div>
        <div class="item item_4">
          <h4>全城裂变</h4>
          <p>平均每个视频<i>带来300-1000的播放量</i></p>
          <p>推送给好友及同城附近的人</p>
          <p>新顾客通过视频获得折扣优惠</p>
          <p>到店消费后继续转发全城裂变</p>
        </div>
      </div>
    </section>
    <section class="container pd100 shop_4">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>适用场景</span>
      </div>
      <ul>
        <li>
          <img src="@/assets/images/shop_4-1.jpg" alt="" />
          <p>餐饮行业</p>
        </li>
        <li>
          <img src="@/assets/images/shop_4-2.jpg" alt="" />
          <p>美容行业</p>
        </li>
        <li>
          <img src="@/assets/images/shop_4-3.jpg" alt="" />
          <p>房地产行业</p>
        </li>
        <li>
          <img src="@/assets/images/shop_4-4.jpg" alt="" />
          <p>服装行业</p>
        </li>
        <li>
          <img src="@/assets/images/shop_4-5.jpg" alt="" />
          <p>酒店民宿</p>
        </li>
        <li>
          <img src="@/assets/images/shop_4-6.jpg" alt="" />
          <p>旅游景点</p>
        </li>
        <li>
          <img src="@/assets/images/shop_4-7.jpg" alt="" />
          <p>健身娱乐</p>
        </li>
        <li>
          <img src="@/assets/images/shop_4-8.jpg" alt="" />
          <p>宠物店</p>
        </li>
      </ul>
    </section>

    <section class="pd60 contact">
      <div class="container">
        <div class="item-title">
          <img src="@/assets/images/title-icon.jpg" alt="" />
          <span>业务合作</span>
        </div>
      </div>
      <div class="main">
        <div class="container">
          <img src="@/assets/images/contact-2.png" alt="" />
          <p>
            请将合作资料发送至邮箱：1487986688@qq.com
            或者加微信：13410419943
          </p>
        </div>
      </div>
    </section> -->

    <footer>
      <div class="main container pd60">
        <div class="item">
          <h4>关于我们</h4>
          <p>公司介绍</p>
          <p>业务合作</p>
        </div>
        <div class="item">
          <h4>联系我们</h4>
          <p>邮箱：1487986688@qq.com</p>
          <p>电话（微信同名）：13410419943，周一至周日 9:30 - 22:30</p>
          <!-- <p>地址：深圳市福田区车公庙泰然八路安华工业区6栋613</p> -->
        </div>
        <div class="wx">
          <div class="wx-item">
            <img src="@/assets/images/wx-1.jpg" alt="" />
            <p>扫码关注公众号</p>
          </div>
          <div class="wx-item">
            <img src="@/assets/images/wx-2.jpg" alt="" />
            <p>业务合作</p>
          </div>
        </div>
      </div>
      <div class="copyright container pd60">
        <p>©2019-2024 深圳漠阳风科技有限公司 版权所有</p>
        <a href="http://beian.miit.gov.cn/">粤ICP备19157531号-2</a>
      </div>
    </footer>
  </div>
</template>
<script>
import "@/assets/css/base.css";
import "@/assets/css/style.css";
export default {};
</script>
